import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ["tab","nav"];
  connect() {
    var that = this
    var nav_id = this.navTarget.dataset.tabsId
    if(nav_id != undefined)
    { $(this.tabTargets).on('show.bs.tab', function(e) {
        localStorage.setItem(nav_id + '_active_tab', that.tabTargets.indexOf(this))
      });

      var activeTab = localStorage.getItem(nav_id + '_active_tab')
      new bootstrap.Tab($(this.tabTargets)[activeTab == null ? 0 : activeTab]).show()
    }
  }
}
