//debounce https://medium.com/pragmatic-programmers/using-stimulus-to-manage-forms-de0e061f751a
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form",'submitter'];
  connect() {
    $(this.formTarget).find("input:enabled, select").filter(function () {
      if($(this).is('input'))   return this.value.trim() == "";
      if($(this).is('select'))  return !$(this).val();
    }).first().focus();
  }

  change(event) {
    this.formTarget.requestSubmit(this.submitterTarget);
  }
}
