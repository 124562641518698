import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ["timer"];
  static values = { expiredAt: String };
  connect() {
    new bootstrap.Toast(this.element).show()
  }

  timerTargetConnected()
  { var that;
    that = this
    const expiredAt = new Date(this.expiredAtValue);
    var seconds =  ~~((expiredAt - new Date()) / 1000);
    this.timerInterval = setInterval(function() {
      if (seconds-- <= 0) {
        clearInterval(that.timerInterval);
        that.timerTarget.innerHTML = "EXPIRED";
      }
      else
      { var mins = ~~(seconds / 60)
        var secs = seconds - mins * 60
        that.timerTarget.innerHTML = `${mins}' ${secs}"`
      }
    }, 1000);
  }

  timerTargetDisconnected()
  { clearInterval(this.timerInterval)
  }
}