import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var that = this
    $(this.element).attr("autocomplete", "off").datepicker({
      language: 'it',
      date: $(this.element).attr('value'),
      startDate: $(this.element).data('start-date'),
      clearBtn: true,
      autoclose: true
    }).change(function(event) {
      that.dispatch('change',{detail: {date: $(this).datepicker("getDate").valueOf() }});
    });
  }
}
