import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static values = {
    forceDate: Object
  }
  connect() {
    let pattern = /\d+/;
    var thenum = this.element.id.match(pattern)[0]
    this.element.value = this.forceDateValue[thenum]
  }
}
