// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "./jquery"
import "bootstrap-datepicker"
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min.js"
import "tablednd"
import "trix"
import "@rails/actiontext"
